<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";
import type { Policy } from "@/interfaces/product.interface";

import { useClientStore } from "@/stores/clientStore";

import ChevronLeftIcon from "../icons/ChevronLeftIcon.vue";
import PlusIconCircle from "../icons/PlusIcon.vue";
import { PlusIcon } from "@heroicons/vue/outline";

import { useMobileButton } from "@/composables/mobileButton";
import { useIcon } from "@/composables/icons";

const clientStore = useClientStore();

const props = defineProps<{
  title?: string;
  cod_ramo?: string;
  policy?: Policy;
}>();

const btnText = computed(() => {
  if (clientStore.client !== "rapihogar") {
    return "Nueva asistencia";
  }
  return "Nueva solicitud";
});

const router = useRouter();

const { mobileBtnLabel } = useMobileButton();
const iconComponent = useIcon(props.cod_ramo);
</script>

<template>
  <header class="flex justify-between items-center mb-4 mt-1">
    <div class="flex items-center">
      <ChevronLeftIcon
        class="text-grayDetails-100 cursor-pointer"
        @click="router.push({ name: 'Products' })"
      />
      <component
        :is="iconComponent"
        class="ml-2 w-[19px] h-[19px] text-skin-major"
      />
      <span class="text-primary text-xs md:text-[15px] pl-1 text-skin-major">{{
        props?.title
      }}</span>
    </div>

    <!-- Desktop -->
    <button
      @click="
        router.push({
          name: 'RequestsCreate',
          query: { policy_id: policy?.id },
        })
      "
      class="btn btn-transition btn-salmon w-48 2xl:w-56 h-[42px] 2xl:h-[46px] hidden md:flex items-center bg-skin-major hover:bg-skin-button-bg-hover"
    >
      <PlusIconCircle class="w-6 m-1 2xl:m-2" />
      <p class="font-bold font-montserrat text-sm 2xl:text-base">
        {{ btnText }}
      </p>
    </button>
    <!-- End Desktop -->

    <!-- Mobile -->
    <button
      @click="
        router.push({
          name: 'RequestsCreate',
          query: { policy_id: policy?.id },
        })
      "
      class="btn btn-transition btn-salmon w-auto justify-center flex z-40 right-5 bottom-0 my-1 md:hidden fixed rounded-full items-center p-3 bg-skin-major hover:bg-skin-button-bg-hover"
    >
      <p
        ref="mobileBtnLabel"
        class="w-28 font-bold font-montserrat text-xs mr-2"
      >
        {{ btnText }}
      </p>
      <PlusIcon class="w-6" />
    </button>
    <!-- End Mobile -->
  </header>
</template>

<style scoped>
.width-button {
  width: 7.5rem;
  opacity: 1;
  white-space: nowrap;
  transition: all 0.2s;
}
.width-scroll {
  width: 0px;
  opacity: 0;
}
</style>
