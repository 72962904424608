<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";
import type { Product } from "@/interfaces/product.interface";

import { useProductStore } from "@/stores/productStore";
import { useClientStore } from "@/stores/clientStore";

import ChevronLeftIcon from "@/components/icons/ChevronLeftIcon.vue";
import { useIcon } from "@/composables/icons";

defineProps<{ step: number }>();

const router = useRouter();
const store = useProductStore();
const clientStore = useClientStore();

const tabs = [{ name: "Datos" }, { name: "Solicitud" }, { name: "Detalles" }];

const currentProduct = computed<Product>(() => {
  return store.currentProduct;
});

const clientTheme = clientStore.theme;

const iconComponent = useIcon(currentProduct.value.cod_ramo_bco);

const btnText = computed(() => {
  if (clientStore.client !== "rapihogar") {
    return "Nueva asistencia";
  }
  return "Nueva solicitud";
});
</script>

<template>
  <div class="flex flex-col md:h-12 md:my-2" :class="clientTheme">
    <div class="flex md:mb-3 items-center">
      <ChevronLeftIcon
        class="text-grayDetails-100 cursor-pointer"
        @click="
          router.push({
            name: 'ProductView',
            params: { id: currentProduct.id },
          })
        "
      />
      <component
        :is="iconComponent"
        class="ml-2 w-[19px] h-[19px] text-skin-major"
      />
      <p class="text-primary text-xs md:text-[15px] pl-1 text-skin-major">
        {{ currentProduct.title }}
        <span class="text-grayDetails-100 mx-2">/</span>
        <span class="text-grayDetails-400 font-medium">{{ btnText }}</span>
      </p>
    </div>
    <div class="mt-4 md:mt-3 flex justify-center items-center">
      <span
        v-for="({ name }, index) of tabs"
        :key="index"
        class="mr-2 md:mr-4 text-grayDetails-100 text-[10px] md:text-xs font-montserrat px-7 md:px-16 font-bold border-grayDetext-grayDetails-100 border-t-2 md:border-t-4"
        :class="[
          step === index || step > index
            ? 'text-greenStatus-200 border-greenStatus-200 border-t-2 md:border-t-4'
            : '',
        ]"
      >
        {{ name }}
      </span>
    </div>
  </div>
</template>

<style scoped></style>
