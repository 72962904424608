<script setup lang="ts">
import type { Coverage } from "@/interfaces/product.interface";
import { ref } from "vue";
import { EyeIcon } from "@heroicons/vue/outline";
import Modal from "@/components/Modal.vue";

const showModal = ref(false);

const currentCoverage = ref<Coverage>();

function openModal(coverage: Coverage) {
  showModal.value = true;
  currentCoverage.value = coverage;
}

function closeModal() {
  showModal.value = false;
}

defineProps<{
  coverages: Coverage[];
  title?: number | string;
}>();
</script>

<template>
  <section class="mt-2 md:mt-8">
    <h3 class="title mb-2 text-[15px] md:text-lg 2xl:text-xl py-4">
      Beneficio {{ title }}
    </h3>
    <article
      :class="[coverages.length >= 3 ? 'h-64 overflow-y-auto ' : 'h-auto']"
      class="border-section flex flex-col p-4 md:py-5 2xl:py-6 md:px-9 2xl:px-12 gap-4"
    >
      <ul
        v-for="coverage of coverages"
        :key="coverage.id"
        class="flex justify-between items-center border-b-[1.5px] border-grayDetails-200 last:pb-0 md:last:pb-4 md:pb-4"
      >
        <li
          class="font-bold font-montserrat text-grayDetails-400 text-xs md:text-[15px]"
        >
          {{ coverage.title }}
          <div class="text-grayDetails-400 text-xs md:text-primary mt-2">
            Saldo total:
            <span class="font-bold">$ {{ coverage.amount }}</span>
          </div>
          <div class="text-greenStatus-200 text-xs md:text-primary mt-2">
            Saldo disponible:
            <span class="font-bold">$ {{ coverage.available_amount }}</span>
          </div>
        </li>
        <EyeIcon
          @click="openModal(coverage)"
          class="text-grayDetails-200 cursor-pointer w-4 md:w-[24px] md:h-[24px] hover:text-skin-major"
        />
      </ul>
    </article>
  </section>
  <Teleport to="body">
    <Modal :isOpen="showModal" @close-modal="closeModal">
      <template #header>
        <div class="mt-6">
          <h1
            class="text-grayDetails-400 text-xs md:text-[15px] font-montserrat font-bold mb-3"
          >
            {{ currentCoverage?.title }}
          </h1>
        </div>
      </template>
      <template #body>
        <span
          v-if="currentCoverage?.legend_2"
          class="text-grayDetails-400 text-xs md:text-sm 2xl:text-[15px] leading-relaxed font-montserrat mb-3"
          v-html="currentCoverage?.legend_2"
        ></span>
      </template>
    </Modal>
  </Teleport>
</template>

<style scoped></style>
