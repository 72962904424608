<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<script setup lang="ts">
import { useUniqueID } from "@/composables/uniqueID";
import type { PropType } from "vue";
import type { InputRadio } from "@/interfaces/Inputs";

const props = defineProps({
  options: { type: Array as PropType<InputRadio[]>, required: true },
  name: { type: String, required: true },
  modelValue: { type: [String, Number], default: undefined },
  vertical: { type: Boolean, default: false },
  error: { type: String, default: "" },
});

const uuid = useUniqueID().getID();
</script>

<template>
  <component
    v-for="(option, $optionIndex) of props.options"
    :key="$optionIndex"
    :class="{ 'ml-2': !props.vertical }"
    :is="props.vertical ? 'div' : 'span'"
    v-bind="{ ...$attrs }"
  >
    <BaseRadio
      :name="props.name"
      :label="option"
      :modelValue="props.modelValue"
      :value="option"
      @update:modelValue="$emit('update:modelValue', $event)"
    />
  </component>
  <BaseErrorMessage
    class="font-medium text-[15px] 2xl:text-base font-montserrat"
    v-if="props.error"
    :id="`${uuid}-error`"
  >
    {{ props.error }}
  </BaseErrorMessage>
</template>

<style scoped></style>
