<script setup lang="ts">
import { computed, ref } from "vue";

import { useFormatDate } from "@/composables/formatDate";
import { downloadPolicy } from "@/services/product.services";

import { useClientStore } from "@/stores/clientStore";

import type { Insured, Policy } from "@/interfaces/product.interface";

import CheckIcon from "@/components/icons/CheckIcon.vue";
import DownloadIcon from "@/components/icons/DownloadIcon.vue";
import EditIcon from "@/components/icons/EditIcon.vue";
import Modal from "@/components/Modal.vue";

const props = defineProps<{
  insured: Insured;
  policy: Policy;
  title?: string | number;
}>();

const emit = defineEmits<{
  (e: "changeAlias", payload: string): void;
}>();

const clientStore = useClientStore();

const showModal = ref(false);

const closeIcon = true;

const alias = ref<string | undefined>("");
const aliasError = ref("");

function changeAlias(payload: string | undefined) {
  if (payload === props.policy.alias) {
    aliasError.value = "No se puede ingresar el mismo alias";
    return;
  }
  if (payload === "" || payload == null) {
    aliasError.value = "Campo requerido";
    return;
  }
  if (payload.length < 3) {
    aliasError.value = "Debe tener al menos 3 carácteres";
    return;
  }
  if (payload.length > 15) {
    aliasError.value = "Debe tener menos de 15 carácteres";
    return;
  }
  aliasError.value = "";
  emit("changeAlias", payload);
  showModal.value = false;
}

function openModal() {
  alias.value = props.policy.alias;
  showModal.value = true;
}

function closeModal() {
  if (aliasError.value) {
    aliasError.value = "";
  }
  showModal.value = false;
}

const fullName = computed(() => {
  const { insured } = props;
  return insured.name + " " + insured.last_name;
});

const clientTheme = clientStore.theme;

const { formatDate } = useFormatDate();

const showFile = (file: string, id: number) => {
  const newBlob = window.URL.createObjectURL(
    new Blob([file], { type: "application/pdf" })
  );

  const link = document.createElement("a") as HTMLAnchorElement;
  link.href = newBlob;
  link.setAttribute("download", `Poliza-${id}.pdf`);
  link.click();

  setTimeout(function () {
    window.URL.revokeObjectURL(newBlob);
  }, 100);
};

async function download(id: number) {
  const { data } = await downloadPolicy(id);
  data && showFile(data, id);
}
</script>

<template>
  <section :class="clientTheme">
    <h3 class="title mb-2 text-[15px] md:text-lg 2xl:text-xl py-4">
      Póliza {{ props.title }}
    </h3>
    <article
      class="border-section flex flex-col p-4 md:py-5 2xl:py-6 md:px-9 2xl:px-12 gap-4"
    >
      <div
        class="md:flex justify-between md:items-center md:mr-44 grid gap-x-7 grid-cols-2 md:grid-rows-1 grid-rows-1"
      >
        <div class="flex flex-col">
          <span class="text-primary text-grayDetails-200 font-bold"
            >Asegurado</span
          >
          <p class="text-primary capitalize text-grayDetails-400 mt-2">
            {{ fullName.toLowerCase() }}
          </p>
        </div>
        <div class="flex flex-col">
          <span class="text-primary text-grayDetails-200 font-bold"
            >Documento</span
          >
          <p class="text-primary text-grayDetails-400 mt-2">
            {{ insured.document_number }}
          </p>
        </div>
        <div class="flex flex-col mt-2 md:mt-0">
          <span class="text-primary text-grayDetails-200 font-bold"
            >N° de póliza</span
          >
          <p class="text-primary text-grayDetails-400 mt-2">
            {{ policy.nro_poliza }}
          </p>
        </div>
        <div class="flex flex-col mt-2 md:mt-0">
          <span class="text-primary text-grayDetails-200 font-bold"
            >Alias de la póliza</span
          >
          <div class="flex items-center mt-2">
            <p class="text-primary text-grayDetails-400">
              {{ policy.alias }}
            </p>
            <EditIcon
              @click="openModal"
              class="w-3 h-3 md:w-5 md:h-5 ml-1 md:ml-2 text-grayDetails-400 cursor-pointer hover:text-skin-major"
            />
          </div>
        </div>
      </div>
      <hr
        v-if="clientStore.client === 'rapihogar'"
        class="w-full my-2 border-grayDetails-100"
      />
      <div
        v-if="clientStore.client === 'rapihogar'"
        class="flex justify-between items-center"
      >
        <div class="flex items-center">
          <div class="p-[6px] mr-2 rounded-full bg-greenStatus-100">
            <CheckIcon class="h-3 text-greenStatus-200" />
          </div>
          <p class="text-greenStatus-200 text-primary mr-2">
            Póliza vigente desde el
            <span class="text-greenStatus-200 text-primary tracking-widest">{{
              formatDate(policy.fec_vig_desde)
            }}</span>
          </p>
        </div>
        <button
          class="p-2 md:px-3 md:py-2 flex items-center justify-center btn w-[35px] h-[35px] md:w-48 2xl:w-[217px] md:h-10 border-[1.5px] border-grayDetails-200 rounded-md group hover:border-skin-major btn-transition"
          @click="download(policy.id)"
        >
          <DownloadIcon
            class="btn-transition w-5 2xl:w-6 group-hover:text-skin-major"
          />
          <p
            class="btn-transition hidden md:block font-bold text-grayDetails-200 text-primary group-hover:text-skin-major"
          >
            Descargar Póliza
          </p>
        </button>
      </div>
    </article>
  </section>

  <Teleport to="body">
    <Modal :isOpen="showModal" :closeIcon="closeIcon" @close-modal="closeModal">
      <template #header>
        <div class="flex flex-col items-center mt-2">
          <h1 class="title text-[15px] md:text-lg 2xl:text-xl mx-4">
            Personalizá el alias de tu póliza
          </h1>
        </div>
      </template>
      <template #body>
        <div class="flex flex-col items-center mt-6">
          <div class="flex flex-col">
            <label class="title text-[13px] md:text-sm 2xl:text-base mb-2"
              >N° de Póliza</label
            >
            <input
              class="base-input bg-grayDetails-300 border-2 border-grayDetails-100 text-grayDetails-400 cursor-not-allowed"
              type="text"
              :value="policy.nro_poliza"
              disabled
            />
          </div>

          <div class="flex flex-col mt-4">
            <label class="title text-[13px] md:text-sm 2xl:text-base mb-2"
              >Alias</label
            >
            <input
              maxlength="15"
              class="base-input border-2 border-grayDetails-100 text-grayDetails-400"
              type="text"
              v-model.trim="alias"
            />
            <p
              class="text-[#D23232] self-start mt-2 font-medium text-[15px] 2xl:text-base font-montserrat"
              v-if="aliasError"
            >
              {{ aliasError }}
            </p>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex flex-col items-center">
          <button
            @click="changeAlias(alias)"
            class="btn btn-transition btn-salmon w-40 md:w-48 2xl:w-56 mt-6 md:mt-4 2xl:mt-6 py-3 bg-skin-major hover:bg-skin-button-bg-hover"
          >
            Modificar
          </button>
        </div>
      </template>
    </Modal>
  </Teleport>
</template>
