<script setup lang="ts">
import { computed } from "vue";
import type { RequestDetail } from "@/interfaces/product.interface";
import { useClientStore } from "@/stores/clientStore";

defineProps<{
  request?: RequestDetail;
}>();

const clientStore = useClientStore();

const text = computed(() => {
  if (clientStore.client !== "rapihogar") {
    return "Asistencia N°:";
  }
  return "Solicitud N°:";
});
</script>
<template>
  <div>
    <h3 class="title mb-2 md:mb-8 text-2xl py-4">
      {{ text }} {{ request?.nro_gestion }}
    </h3>
    <h4 class="title text-base md:text-xl mb-1 md:mb-3">
      Datos de la solicitud
    </h4>
    <article
      class="border-section flex flex-col p-4 md:py-5 2xl:py-6 md:px-9 2xl:px-12 gap-4"
    >
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-x-24">
        <div class="grid grid-cols-3 md:grid-cols-4 text-primary">
          <p class="table-card-title">Cobertura</p>
          <p class="font-medium grid-initial col-span-2 md:col-span-3">
            {{ request?.producto }}
          </p>
        </div>
        <div class="grid grid-cols-3 md:grid-cols-4 text-primary">
          <p class="table-card-title">Servicio</p>
          <p class="font-medium col-span-2 md:col-span-3">
            {{ request?.servicios }}
          </p>
        </div>
        <div class="grid grid-cols-3 md:grid-cols-4 text-primary">
          <p class="table-card-title">Descripción</p>
          <p class="font-medium col-span-2 md:col-span-3">
            {{ request?.descripcion_problema }}
          </p>
        </div>
        <div class="grid grid-cols-3 md:grid-cols-4 text-primary">
          <p class="table-card-title">N° de Póliza</p>
          <p class="font-medium col-span-2 md:col-span-2">
            {{ request?.nro_poliza }}
          </p>
        </div>
        <div class="grid grid-cols-3 md:grid-cols-4 text-primary">
          <p class="table-card-title">Provincia</p>
          <p class="font-medium col-span-2 md:col-span-3">
            {{ request?.provincia?.name }}
          </p>
        </div>
        <div class="grid grid-cols-3 md:grid-cols-4 text-primary">
          <p class="table-card-title">Ciudad</p>
          <p class="font-medium col-span-2 md:col-span-3">
            {{ request?.ciudad?.name }}
          </p>
        </div>
        <div class="grid grid-cols-3 md:grid-cols-4 text-primary">
          <p class="table-card-title">Domicilio</p>
          <p class="font-medium col-span-2 md:col-span-3">
            {{ request?.direccion }}
          </p>
        </div>
      </div>
    </article>
  </div>
</template>

<style scoped></style>
