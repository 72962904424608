<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import { ref, watchEffect, computed } from "vue";
import { storeToRefs } from "pinia";
import { getMessaging, onMessage } from "@firebase/messaging";

import type { RequestDetail } from "../interfaces/product.interface";
import {
  useNotificationStore,
  type Notification,
} from "@/stores/notifications";

import { useClientStore } from "@/stores/clientStore";
import { useProductStore } from "../stores/productStore";
import {
  fetchNotificationsByRequest,
  markAllAsRead,
} from "@/services/notification.services";

import ChevronLeftIcon from "@/components/icons/ChevronLeftIcon.vue";

import RequestData from "@/components/dashboard/request/RequestData.vue";
import RequestTracking from "../components/dashboard/request/RequestTracking.vue";
import RequestNotification from "../components/dashboard/request/RequestNotification.vue";

const props = defineProps<{
  id: RequestDetail["id"];
}>();

const router = useRouter();
const route = useRoute();

const clientStore = useClientStore();
const clientTheme = clientStore.theme;

const productStore = useProductStore();
const { loadRequestDetail } = productStore;

const notificationStore = useNotificationStore();
const { getNotifications } = notificationStore;
const { showNotification, newNotification } = storeToRefs(notificationStore);

const requestDetail = ref<RequestDetail>();
const requestNotifications = ref<Notification[]>();
const managementNumber = Number(route.query?.nro_gestion);

async function getAllNotifications(managementNumber: number) {
  try {
    const { data } = await fetchNotificationsByRequest(managementNumber);
    requestNotifications.value = data;
    await getNotifications();
    return data;
  } catch (error) {
    console.log({ error });
  }
}

async function markAllNotificationsAsRead(managementNumber: number) {
  try {
    await markAllAsRead(managementNumber);
  } catch (error) {
    console.log({ error });
  }

  await getNotifications();
  await getAllNotifications(managementNumber);
}

watchEffect(() => {
  const messaging = getMessaging();

  onMessage(messaging, async (payload) => {
    if (payload) {
      showNotification.value = true;
      newNotification.value = payload;
      await getNotifications();
      await getAllNotifications(managementNumber);
    }
  });
});

const response = await Promise.all([
  loadRequestDetail(props.id),
  getAllNotifications(managementNumber),
]);

requestDetail.value = response[0];
requestNotifications.value = response[1];

const coverageText = computed(() => {
  if (clientStore.client !== "rapihogar") {
    return "asistencia";
  }
  return "solicitud";
});
</script>

<template>
  <div
    :class="clientTheme"
    class="min-h-full flex-col flex-grow justify-start py-12 px-5 md:px-24 gap-4"
  >
    <header class="flex justify-between items-center mb-4 md:mb-8 mt-1">
      <button
        @click="router.back()"
        class="flex btn-transition items-center text-grayDetails-100"
      >
        <ChevronLeftIcon
          class="w-[23px] h-[23px] md:mr-1 text-grayDetails-100"
        />
        <span class="text-primary font-montserrat text-skin-major"
          >Detalles de la {{ coverageText }}</span
        >
      </button>
    </header>

    <main class="flex flex-col gap-y-6 md:gap-y-8">
      <RequestData :request="requestDetail" />

      <div class="grid grid-cols-1 md:grid-cols-3 gap-y-6 gap-x-9">
        <RequestTracking
          :milestones="requestDetail?.hitos"
          class="col-span-1"
        />

        <div class="col-span-1 md:col-span-2">
          <h4 class="title text-base md:text-xl mb-1 md:mb-3">
            Notificaciones
          </h4>

          <RequestNotification
            :notifications="requestNotifications"
            @refresh-notifications="getAllNotifications(managementNumber)"
            @mark-all-notifications-as-read="
              markAllNotificationsAsRead(managementNumber)
            "
            class="border-section md:h-[29rem] md:overflow-y-auto"
          />
        </div>
      </div>
    </main>
  </div>
</template>

<style scoped></style>
