import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
} from "vue-router";

import { useAuthStore } from "@/stores/authStore";

import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import ProductView from "@/views/ProductView.vue";
import CreateRequest from "@/views/CreateRequest.vue";
import RequestView from "@/views/RequestView.vue";
import AuthLayout from "@/components/AuthLayout.vue";
import RefundFormView from "@/views/RefundFormView.vue";
import { useTitle } from "@vueuse/core";
import { computed } from "vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: "/products",
    component: () => import("../components/DefaultLayout.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "/products",
        name: "Products",
        meta: { title: "Mis productos" },
        component: () => import("@/views/Products.vue"),
      },
      {
        path: "/requests",
        name: "Requests",
        meta: { title: "Mis solicitudes" },
        component: () => import("@/views/Requests.vue"),
      },
      {
        path: "/products/:id",
        name: "ProductView",
        component: ProductView,
        props: true,
        meta: { title: "Producto" },
      },
      {
        path: "/requests/create",
        name: "RequestsCreate",
        meta: { title: "Nueva solicitud" },
        component: CreateRequest,
      },
      {
        path: "/notifications",
        name: "Notifications",
        meta: { title: "Notificaciones" },
        component: () => import("@/views/Notifications.vue"),
      },
      {
        path: "/request/:id",
        name: "RequestView",
        component: RequestView,
        props: true,
        meta: { title: "Detalle solicitud" },
      },
      {
        path: "/:pathMatch(.*)*",
        name: "State",
        meta: { title: "" },
        component: () => import("@/views/State.vue"),
      },
    ],
  },
  {
    path: "/auth",
    redirect: "/login",
    name: "Auth",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "Login",
        component: Login,
        meta: { title: "Inicio" },
      },
      {
        path: "/register",
        name: "Register",
        component: Register,
        meta: { title: "Registrar" },
      },
    ],
  },
  {
    path: "/refund-form",
    name: "RefundFormView",
    component: RefundFormView,
    meta: { title: "Formulario" },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  const authStore = useAuthStore();

  const title = computed(() => {
    if (import.meta.env.VITE_CLIENT_NAME === "rus")
      return "Río Uruguay Seguros";
    if (import.meta.env.VITE_CLIENT_NAME === "galicia")
      return "Galicia Seguros";
    return "RapiHogar";
  });

  const metaTitle = computed(() => {
    if (
      import.meta.env.VITE_CLIENT_NAME !== "rapihogar" &&
      to.name == "Requests"
    )
      return "Mis asistencias";
    if (
      import.meta.env.VITE_CLIENT_NAME !== "rapihogar" &&
      to.name == "RequestsCreate"
    )
      return "Nueva asistencia";
    if (
      import.meta.env.VITE_CLIENT_NAME !== "rapihogar" &&
      to.name == "RequestView"
    )
      return "Detalle asistencia";
    return to.meta.title;
  });

  document.title = title.value;

  to.meta.title = metaTitle.value;

  useTitle("", { titleTemplate: ` ${to.meta.title} | ${document.title}` });

  if (to.meta.requiresAuth && !authStore.token) return "/login";

  if (authStore.token && (to.name === "Login" || to.name === "Register"))
    return "/products";
});

export default router;
