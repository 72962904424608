<script setup lang="ts">
import { watchEffect } from "vue";
import { RouterView } from "vue-router";
import { onMessage, getMessaging } from "@firebase/messaging";

import MainNavbar from "@/components/dashboard/MainNavbar.vue";
import ModalMessage from "@/components/ModalMessage.vue";
import FooterRus from "./dashboard/FooterRus.vue";
import TheAlert from "./TheAlert.vue";

import { useNotificationStore } from "@/stores/notifications";
import { useAuthStore } from "@/stores/authStore";
import { storeToRefs } from "pinia";

const notificationStore = useNotificationStore();
const { getNotifications, closeNotificationAlert } = notificationStore;
const { newNotification, showNotification } = storeToRefs(notificationStore);

watchEffect(() => {
  const messaging = getMessaging();

  onMessage(messaging, async (payload) => {
    if (payload) {
      showNotification.value = true;
      newNotification.value = payload;
      await getNotifications();
    }
  });
});

const authStore = useAuthStore();
const { showMessage } = storeToRefs(authStore);

function closeMessage() {
  showMessage.value = false;
}
</script>

<template>
  <div class="min-h-screen flex flex-col">
    <MainNavbar />

    <ModalMessage :show-message="showMessage" @close-message="closeMessage" />

    <TheAlert
      :notification="newNotification"
      :visible="showNotification"
      @close-notification="closeNotificationAlert"
    />

    <RouterView v-slot="{ Component, route }">
      <template v-if="Component">
        <Suspense>
          <template #default>
            <Transition
              enter-active-class="animate__animated animate__fadeIn"
              leave-active-class="animate__animated animate__fadeOut"
              mode="out-in"
              appear
            >
              <main
                class="w-full relative flex flex-col justify-start md:justify-center flex-grow"
                :key="route.path"
              >
                <component :is="Component" />
                <FooterRus class="mt-auto mb-4" />
              </main>
            </Transition>
          </template>
          <template #fallback>
            <Transition name="fade" mode="out-in" appear>
              <LoadingStatus
                class="absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%]"
              />
            </Transition>
          </template>
        </Suspense>
      </template>
    </RouterView>
  </div>
</template>

<style scoped></style>
