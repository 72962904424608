<script setup lang="ts">
import { computed } from "vue";

import { ClientName, useClientStore } from "@/stores/clientStore";

import PhoneIcon from "@/components/icons/PhoneIcon.vue";
import WhatsappIcon from "@/components/icons/WhatsappIcon.vue";
import { storeToRefs } from "pinia";

const clientStore = useClientStore();

const {
  client,
  whatsappNumber: whatsapp,
  phoneNumber: phone,
} = storeToRefs(clientStore);

const clientTheme = computed(() => {
  if (client.value === ClientName.RioUruguaySeguros) {
    return "theme-rus md:shadow-none";
  }
  if (client.value === ClientName.Galicia) {
    return "theme-galicia md:shadow-md";
  }
  return "theme-rapi-hogar md:shadow-none";
});

const whatsappNumber = whatsapp.value;
const phoneNumber = phone.value;

const disabledContact = computed(() => {
  return client.value === ClientName.Rapihogar || client.value === "";
});
</script>

<template>
  <header
    :class="clientTheme"
    class="nav-bar from-skin-gradient-from to-skin-gradient-to shadow-md shadow-gray-500 z-10"
  >
    <div class="nav-bar-size">
      <div class="nav-bar-position">
        <!-- <LogoRus
          svgColor="#FFFFFF"
          v-if="clientStore.client === 'rus'"
          class="text-white logo-size h-7 my-0 md:h-12 md:my-1"
        /> -->
        <img
          v-if="client === 'rapihogar' || client === 'rus'"
          class="logo-size"
          src="@/assets/logo_rapihogar.svg"
          alt="Rapi Hogar"
        />
        <img
          v-if="client === 'galicia'"
          class="logo-size w-10 md:w-14"
          src="@/assets/logo_galicia.svg"
          alt="Galicia"
        />

        <div v-if="!disabledContact" class="flex gap-9 text-skin-iconalt">
          <div class="hidden md:flex items-center">
            <WhatsappIcon />
            <span
              class="pl-2 font-montserrat font-medium text-sm 2xl:text-base"
            >
              {{ whatsappNumber }}</span
            >
          </div>
          <div class="hidden md:flex items-center">
            <PhoneIcon />
            <span
              class="pl-2 font-montserrat font-medium text-sm 2xl:text-base"
            >
              {{ phoneNumber }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped></style>
