<script setup lang="ts">
import { useFormComponent } from "@/composables/SetupFormComponent";
import { useUniqueID } from "@/composables/uniqueID";

interface Props {
  label: string;
  modelValue: string | number;
  value: string | number;
  error?: string;
}

const props = withDefaults(defineProps<Props>(), {
  label: "",
  modelValue: "",
  error: "",
});

const { updateValue } = useFormComponent(props);

const uuid = useUniqueID().getID();
</script>

<template>
  <input
    type="radio"
    class="base-radio"
    v-bind="{ ...$attrs, onChange: updateValue }"
    :checked="props.modelValue === props.value"
    :id="uuid"
  />
  <label
    class="text-xs 2xl:text-sm font-montserrat font-medium text-grayDetails-400"
    v-if="props.label"
    :for="uuid"
    >{{ props.label }}</label
  >
  <BaseErrorMessage
    class="font-medium text-[15px] 2xl:text-base font-montserrat"
    v-if="props.error"
    :id="`${uuid}-error`"
  >
    {{ props.error }}
  </BaseErrorMessage>
</template>

<style scoped>
input:checked + label {
  color: blue;
  font-weight: bold;
}
</style>
